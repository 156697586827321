import { AbsenceCalculationType, AbsenceOptionUnit } from '@app/enums';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { StatusType } from '@sb/ui';

import { PermissionState } from '../../auth/auth.model';
import { UnsafeAction } from '../../interfaces';
import { AbsenteeOptionModel } from '../absentee-option/absentee-option.model';
import { EmployeeModel } from '../employee/employee.model';
import { SharedModelState } from '../shared.model.state';

// tslint:disable-next-line:no-empty-interface
export interface AbsenceState extends SharedModelState<AbsenceModel> {}

export interface AbsenceMap {
  [id: string]: AbsenceModel;
}

export enum AbsenceStatus {
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  PENDING = 'Pending',
}

// Only for translations
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const translations = [_('Approved'), _('Declined'), _('Pending')];

export interface AbsenceModel {
  id?: string;
  account_id: string;
  user_id: string;
  startdate: string;
  enddate: string;
  roster_action?: RosterActions;
  exclude?: string;
  absentee_option_id: string;
  status: AbsenceStatus;
  note: string;
  hours: string;
  day_total: number;
  days: number;
  wait_hours: string;
  surcharge_id: string;
  partial_day: string;
  start_time: string;
  end_time: string;
  created: string;
  created_by: string;
  updated: string;
  modified_by: string;
  reviewed: string;
  reviewed_by: string;
  deleted?: boolean;
  saving?: boolean;
  AbsenteeDay: KeyedAbsenteeDays;
  ReviewedBy: { first_name: string; prefix: string; last_name: string; id: string; name: string };
  startDateTime?: Date;
  endDateTime?: Date;
  hide_days_without_hours?: boolean;
  absence_unit?: AbsenceOptionUnit;
  canEdit?: boolean;
  canDelete?: boolean;
  open_ended?: boolean;
  employeeName?: string;

  [propName: string]: any; // allow for custom properties
}

export interface KeyedAbsenteeDays {
  [date: string]: AbsenteeDay;
}

export interface AbsenteeDay {
  date: string;
  hours: string;
  wait_hours: string;
  partial_day: boolean;
  start_time: string;
  department_id: string;
  coc: string;
  salary: string;
  hidden?: boolean;
  startDate?: Date;
  endDate?: Date;
  startTime?: string;
  endTime?: string;
  position?: number;
  time_off_balance_id?: string;
  user_id?: string;
  days?: string;
  from_time: string | null;
  until_time: string | null;
}

export enum RosterActions {
  HIDE = 'hide',
  NONE = 'none',
  MOVE_TO_OPEN_SHIFT = 'move_to_open_shift',
}

export interface EnhancedAbsenceModel extends AbsenceModel {
  absenteeOption: AbsenteeOptionModel;
  Employee: EmployeeModel;
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
}

export interface AbsenceWithPeriodSumModel extends AbsenceModel {
  periodHours: number;
  periodPay: number;
  absenteeOption?: AbsenteeOptionModel;
}

export type AbsenteePermission = 'delete' | 'approve' | 'edit' | 'view';

export interface AbsenteePermissionCheckData {
  absence: EnhancedAbsenceModel;
  departments: string[];
  userId: string;
  permissionState: PermissionState;
}

export interface AbsenceLoadRequest {
  userId?: string;
  minDate: string;
  maxDate: string;
  status?: string;
  include?: string;
  only_open_ended?: boolean;
}

export interface LoadAbsenceSuccessAction extends UnsafeAction {
  requestData: AbsenceLoadRequest;
}

export interface AbsenteeDaysData {
  hasScheduleConflict: boolean;
  hasContractConflict: boolean;
  hasAbsenceConflict: boolean;
  hasConflictWithSameOptionId: boolean;
  expectedHours: string;
  hours?: number;
}

export interface AbsenceInfoRequest {
  Absentee: {
    partial_day: boolean;
    startdate: string;
    enddate?: string;
    absentee_option_id: string;
  };
  ids: string[];
}

export interface AbsenceExpectedRequest {
  user_id: string;
  startdate: string;
  enddate: string;
  absence_type_id: string;
}

export type AbsenceTimeOffDetermination = 'SCHEDULED' | 'CONTRACT' | 'NONE';

export interface AbsenceInfoEmployee {
  already_absent: boolean;
  contract: number;
  contract_type: string;
  contract_type_id: string;
  expected_hours: number;
  id: string;
  scheduled: number;
  time_off_request_source_hours: AbsenceTimeOffDetermination;
}

export interface AbsenceInfoResponse {
  [id: string]: AbsenceInfoEmployee;
}

export interface AbsenceExpectedResponse {
  contract: number;
  expected_hours: number;
  scheduled: number;
  week_contract: {
    contract_hours: string;
    mo: number;
    tu: number;
    we: number;
    th: number;
    fr: number;
    sa: number;
    su: number;
  };
  days: {
    [date: string]: AbsenceExpectedDay;
  };
}

export interface AbsenceExpectedDay {
  calculation_type: AbsenceCalculationType;
  contract_hours: number;
  date: string;
  hours: number;
  partial_day: boolean;
  scheduled_hours: number;
  start_time: string;
}

export interface AbsenceConflictOptions {
  partialDay?: boolean;
  startTime?: string;
  fromTime?: string;
  untilTime?: string;
  hours?: string;
}

export interface BulkUpdateAbsenceRequest {
  Absentee: {
    absentee_option_id: string;
    partial_day: boolean;
    startdate: string;
    note: string;
    time_off_balance_id?: string;
    roster_action: RosterActions;
    start_time?: string;
    end_time?: string;
    enddate?: string;
  };
  UserDays?: BulkUpdateAbsenceUserDays[];
  UserHours?: BulkUpdateAbsenceUserHours[];
}

export interface BulkUpdateAbsenceUserDays {
  user_id: string;
  days: number;
}
export interface BulkUpdateAbsenceUserHours {
  user_id: string;
  hours: number;
}

export interface AbsenceReviewCheck {
  message: string;
  status: StatusType;
}

export interface AbsenceMarkAsReturnRequest {
  id: string;
  endDate: string;
}
